<template>
  <div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row type="flex" :gutter="24">
        <a-col :span="24" :lg="24" class="mb-24">
          <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
            <template #title>
              <h5 class="mb-0">Preference details</h5>
            </template>
            <a-row :gutter="[6]">
              <a-col :span="6">
                <a-form-item class="mb-10" label="Name" :colon="false">
                  <a-input v-model="name" placeholder="eg. Joking" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-button type="primary" html-type="submit"> Submit </a-button>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import preferenceAPI from '../../api/preference';
import router from '../../router';

export default {
  data() {
    return {
      name: '',
      preferenceEditData: null,
      isEdit: false,
      form: this.$form.createForm(this, { name: "editPreference" }),
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, preference) => {
        if (!err) {
          preference.name = this.name;
          if (!this.isEdit) {
            preferenceAPI.createPreference(preference).then((response) => {
              if (response.status == 200) {
                router.push('/preferences/preferences-list')
              }
            }).catch((error) => {
              console.log(error);
              router.push('/preferences/preferences-list');
            })
          } else {
            preferenceAPI.updatePreference(this.preferenceEditData.preference.id, preference).then((response) => {
              if (response.status == 200) {
                router.push('/preferences/preferences-list')
              }
            }).catch((error) => {
              console.log(error);
              router.push('/preferences/preferences-list');
            })
          }
        }
      });
    },
    async getPreferenceById(id) {
      return preferenceAPI.getPreference(id);
    }
  },
  async mounted() {
    if (this.$route.params.id !== undefined) {
      this.preferenceEditData = await this.getPreferenceById(this.$route.params.id); // get city name by id to edit it
      this.isEdit = true;
      this.name = this.preferenceEditData.preference.name;
    }
  },
};
</script>
