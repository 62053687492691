import client from "./client";

const createPreference = (data) => {
  return client
    .post("/preference", data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const getPreference = (id) => {
  return client
    .get(`/preference/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const updatePreference = (id, data) => {
  return client
    .put(`/preference/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const getPreferences = (filter) => {
  return client
    .get(`/preferences`, filter)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => error.response);
};

const deletePreferences = (data) => {
  return client
    .put(`/preference`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};


const preferenceAPI = {
  getPreferences,
  createPreference,
  getPreference,
  updatePreference,
  deletePreferences,
};

export default preferenceAPI;
